class ConfigManager {
    static API_URL: string = "";
    static API_BACK_URL: string = "https://traspasoback.azurewebsites.net";
    static JSON_HEADER: { "Content-Type": string } = { "Content-Type": "application/json" };
    static AMB: string = "development";
    static WSP_NUM: string = "56929495313";
    static KEY: string = "PVvAX99miEVH76JKeTCGCW0nQ0PqduuD";
    static ACTIVE_FAO: boolean = true;
    static MARKUP: boolean = false;
    static MARKUP_INDEX: number = 3;
    static SHOW_MARKUP: boolean = false;
    static SHOW_TIMER: boolean = true;
    static ENCRYPT_DATA: boolean = true;
    static VERSION: string = "2.0.4.6";

    static getApiUrl(): string {
        return this.API_URL;
    }

    static getApiBackUrl(): string {
        return this.API_BACK_URL;
    }

    static getJsonHeader(): { "Content-Type": string } {
        return this.JSON_HEADER;
    }

    static getAmb(): string {
        return this.AMB;
    }

    static getWspNum(): string {
        return this.WSP_NUM;
    }

    static getKey(): string {
        return this.KEY;
    }

    static isActiveFao(): boolean {
        return this.ACTIVE_FAO;
    }

    static isMarkupEnabled(): boolean {
        return this.MARKUP;
    }

    static isShowMarkupEnabled(): boolean {
        return this.SHOW_MARKUP;
    }

    static isShowTimerEnabled(): boolean {
        return this.SHOW_TIMER;
    }

    static getIndex(): number {
        return this.MARKUP_INDEX;
    }
}

export default ConfigManager;
