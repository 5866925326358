import images from "../../assets/images";

const disclaimer = {
  highlights: [
    {
      head: "Primeros en rentabilidad",
      body: "En todos los fondos en 2024.",
      image: images.aumenta,
    },
    {
      head: "La mejor experiencia de servicio",
      body: "Según Ranking PXI 2024, sector AFP.",
      image: images.heart,
    },
    {
      head: "La AFP con más afiliados",
      body: "Más personas confían en nosotros.",
      image: images.star,
    }
  ],
  footer: {
    text: `Infórmate sobre la calidad de servicio de las AFP en la herramienta <strong><a href='https://www.spensiones.cl/infoydec' target="_blank">Infórmate y Decídete</a></strong>, disponible en la página web de la Superintendencia de Pensiones. Entrega tu opinión a la Superintendencia de Pensiones sobre la calidad del servicio de tu AFP, <strong><a href='https://es.research.net/r/spensiones?utm_source=afpmodelo&utm_medium=organic&utm_campaign' target="_blank">comienza aquí.</a></strong><br><br>\"La rentabilidad es variable, por lo que nada garantiza que las rentabilidades pasadas se repitan en el futuro. Infórmese sobre la rentabilidad de su Fondo de Pensiones, las comisiones y la calidad de servicio de las AFP en el sitio web de la Superintendencia de Pensiones: <strong><a href='https://www.spensiones.cl/' target="_blank">www.spensiones.cl</a></strong>\". Fuente: Inversiones y Rentabilidad de los Fondos de Pensiones correspondiente a diciembre 2024. Periodo correspondiente a enero 2024 — diciembre 2024. Rentabilidad de la cuota expresada en forma real: Fondo A: 9,59%, Fondo B: 8,00%, Fondo C: 4,02%, Fondo D: 0,88%, Fondo E: 0,62%. Estructura de comisiones por depósitos de cotizaciones vigentes a Enero de 2025 – Capital: 1,44%, Cuprum: 1,44%, Habitat: 1,27%, Modelo: 0,58%, Planvital: 1,16%, Provida: 1,45%, Uno: 0,49% – Para afiliados dependientes, independientes y voluntarios. Número de afiliados por AFP vigente según Informe Estadístico Mensual de Afiliados y Cotizantes de la Superintendencia de Pensiones (2.803.898 afiliados al 31 de octubre de 2024). Primer lugar en experiencia de servicio según ranking PXI – Praxis Xperience Index 2024 para el Sector AFP.`
  }
};

export default disclaimer;