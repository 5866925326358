import { Form } from "react-bootstrap";
import { useState, useContext } from "react";
import { SendRequest } from "../../services/RequestHandler";
import Swal from "sweetalert2";
import ConfigManager from "../../settings/ConfigManager";
import Context from "../../context/context";
import Tooltip from "../../components/Tooltip/Tooltip";
import { Encrypt, Decrypt } from "../../utils/SessionCrypto/Crypto";
import images from "../../assets/images";
import Button from "../../components/Button/Button";

export default function FormClaveUnica() {
	const { state, actions } = useContext(Context);
	const [serial, setSerialNumber] = useState("");

	const DocumentChange = (event) => {
		event.preventDefault();

		const re1try = /^\b[aA]$/;
		const re2try = /^\b[aA][0-9]{1,9}$/;
		const reOnlyNumbers = /^\b[0-9]{1,9}$/;
		if (re1try.test(event.target.value) || event.target.value === "") {
			setSerialNumber(event.target.value);
		} else if (
			event.target.value.length > 1 &&
			re2try.test(event.target.value)
		) {
			setSerialNumber(event.target.value);
		} else if (reOnlyNumbers.test(event.target.value)) {
			setSerialNumber(event.target.value);
		}
	};

	const AutenticateCI = async (e) => {
		e.preventDefault();

		await setLoading(true);

		if (ConfigManager.ACTIVE_FAO) {
			window.location.replace("/fao");
		} else if (serial !== "") {
			var data = Decrypt("data");
			data.autenticacion = "CU";

			Encrypt("data", data);

			var result = await SendRequest({
				url: `${ConfigManager.API_BACK_URL}/api/ecert/authenticate/CI/${data.run}/${data.device}`,
				method: "POST",
				body: null
			});

			if (result.Exito) {
				await ValidateCI(data, result.ObjetoGenerico);
			}
		} else {
			Swal.fire({
				icon: "error",
				text: "Por favor, ingrese su número de documento.",
				confirmButtonText: "Ok",
				allowOutsideClick: false,
			});
		}
	};

	const ValidateCI = async (data, token) => {

		var result = await SendRequest({
			url: `${ConfigManager.API_BACK_URL}/api/ecert/validate/ci`,
			method: "POST",
			body: JSON.stringify({
				Datos: {
					Run: data.run,
					NumeroSerie: serial,
				},
				Token: token,
				Device: data.device
			})
		});

		if (result.Exito) {
			await AuthenticateCU(data);
		} else {
			var dataCliente = result.ObjetoGenerico.DatosCliente;

			var valida = dataCliente.EstadoRespuesta !== "Consulta exitosa";
			var bloqueada = dataCliente.IndicadorBloqueo !== "NOBLOQUEADO";
			var vencida = dataCliente.Vigencia !== "S";

			if (valida) {
				Swal.fire({
					title: "Número de documento incorrecto",
					icon: "error",
					text: "El número de documento que ingresaste no corresponde al de tu cédula de identidad. Inténtalo nuevamente.",
					confirmButtonText: "Ok",
					allowOutsideClick: false,
				});
			} else if (bloqueada) {
				Swal.fire({
					icon: "error",
					text: "Tu cédula está bloqueada.",
					confirmButtonText: "Ok",
					allowOutsideClick: false,
				}).then((result) => {
					if (result.isConfirmed) {
						Swal.fire({
							icon: "info",
							html: "Puedes completar tu traspaso mediante tu <b>clave de seguridad</b>.",
							confirmButtonText: "Ok",
							allowOutsideClick: false,
						});
					}
				});
			} else if (vencida) {
				Swal.fire({
					icon: "error",
					text: "Tu cédula está vencida.",
					confirmButtonText: "Ok",
					allowOutsideClick: false,
				}).then((result) => {
					if (result.isConfirmed) {
						Swal.fire({
							icon: "info",
							html: "Puedes completar tu traspaso mediante tu <b>clave de seguridad</b>.",
							confirmButtonText: "Ok",
							allowOutsideClick: false,
						});
					}
				});
			}
			await setLoading(false);
		}
	};

	const AuthenticateCU = async (data) => {
		var result = await SendRequest({
			url: `${ConfigManager.API_BACK_URL}/api/ecert/authenticate/${data.run}/${data.device}`,
			method: "POST",
			body: null
		});

		if (result.Exito) {
			data.token = result.ObjetoGenerico.TokenUser.TokenUser;
			Encrypt("data", data);

			await SolicitudCU(data);
		} else {
			Swal.fire({
				icon: "error",
				text: result.Descripcion,
				confirmButtonText: "Ok",
				allowOutsideClick: false,
			});
			await setLoading(false);
		}
	};

	const SolicitudCU = async (data) => {
		var result = await SendRequest({
			url: `${ConfigManager.API_URL}/api/ecert/validate/cu`,
			method: "POST",
			body: JSON.stringify({
				Run: data.run,
				UrlCallback: `${window.location.origin}/validate`,
				Nombres: data.name,
				ApellidoPaterno: data.fatherName,
				ApellidoMaterno: data.motherName,
				Correo: data.email,
				Token: data.token,
				NumeroSerie: serial,
				Device: data.device
			})
		});

		if (result.Exito) {
			await setLoading(false);

			var resultado = result.ObjetoGenerico.Resultado;

			data.identificador = resultado.Identificador;
			data.tokentvi = resultado.IdentificadorPrevired;

			Encrypt("data", data);

			let timerInterval;
			Swal.fire({
				icon: "success",
				title: "ClaveÚnica",
				html: '<ul>Se han envado los datos de manera exitosa.</ul><ul>Se redireccionará automáticamente al sitio. <b class="d-none"></b></ul>',
				timer: 5000,
				timerProgressBar: true,
				allowOutsideClick: false,
				didOpen: () => {
					Swal.showLoading();
					const b = Swal.getHtmlContainer().querySelector("b");
					timerInterval = setInterval(() => {
						b.textContent = Swal.getTimerLeft();
					}, 100);
				},
				willClose: () => {
					clearInterval(timerInterval);
				},
			}).then((timerResult) => {
				if (timerResult.dismiss === Swal.DismissReason.timer) {
					window.location.replace(resultado.UrlClaveUnica);
				}
			});
		} else {
			const steps = [];
			var arr = result.ObjetoGenerico.ErroresValidacion;
			for (var i = 1; i <= arr.length; i++) {
				steps.push(i.toString());
			}

			const swalQueueStep = Swal.mixin({
				confirmButtonText: "Siguiente",
				cancelButtonText: "Anterior",
				progressSteps: steps,
				reverseButtons: true,
			});

			const values = [];
			let currentStep;

			await setLoading(false);

			for (currentStep = 0; currentStep < steps.length;) {
				var text = `${arr[currentStep]}`;

				const resultq = await swalQueueStep.fire({
					title: result.Descripcion,
					showCancelButton: currentStep > 0,
					html: text,
					currentProgressStep: currentStep,
				});

				if (resultq.value) {
					values[currentStep] = resultq.value;
					currentStep++;
				} else if (resultq.dismiss === Swal.DismissReason.cancel) {
					currentStep--;
				} else {
					break;
				}
			}
		}
	};

	const setLoading = async (isLoading) => {
		actions({ type: "setState", payload: { ...state, isLoading } });
	};

	return (
		<Form onSubmit={AutenticateCI}>
			{ConfigManager.ACTIVE_FAO === false && (
				<Form.Group>
					<div className="row">
						<div className="col-10">
							<h6 className="subs-title">
								Ingresa el Número de documento de tu Cédula de Identidad
							</h6>
						</div>
						<div className="col-2">
							<Tooltip text="Número de Documento" image={images.cedula} />
						</div>
					</div>
					<div className="row">
						<div className="col-12">
							<Form.Control
								value={serial}
								type="text"
								className=""
								placeholder="Ingresa número de documento"
								name="numdoc"
								onChange={DocumentChange}
							/>
						</div>
					</div>
				</Form.Group>
			)}
			<div className="mt-3">
				<Button id="B-TW-0012" type="submit" variant="primary" loading={state.loading} block>
					Cambiarme a AFP Modelo
				</Button>
			</div>
		</Form>
	);
}