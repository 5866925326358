import CryptoJS from "crypto-js";
import { secretKey } from "./secret-key";
import ConfigManager from "../../settings/ConfigManager";

// Tipo para el valor encriptado
type EncryptedValue = string;

// Tipo para el valor que se puede encriptar
type ValueToEncrypt = object;

// Desencriptar un valor
export const Decrypt = (key: string): any | null => {
	// Obtener el valor del sessionStorage
	const encryptedValue = sessionStorage.getItem(key);

	if (encryptedValue === null) {
		console.error("No value found in sessionStorage for the given key.");
		return null;
	}

	try {
		if (ConfigManager.ENCRYPT_DATA) {
			const bytes = CryptoJS.AES.decrypt(encryptedValue, secretKey);
			const decrypted = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
			return decrypted;
		} else {
			const decrypted = JSON.parse(encryptedValue);
			return decrypted;
		}
	} catch (error) {
		console.error("Error during decryption:", error);
		return null; // Opcional: retornar null si hay un error
	}
};

// Encriptar un valor
export const Encrypt = (key: string, value: ValueToEncrypt): EncryptedValue | null => {
	try {
		var p = JSON.stringify(value);
		if (ConfigManager.ENCRYPT_DATA) {
			const encryptedValue = CryptoJS.AES.encrypt(p, secretKey).toString();
			sessionStorage.setItem(key, encryptedValue);
			return encryptedValue;
		} else {
			sessionStorage.setItem(key, p);
			return p;
		}
	} catch (error) {
		console.error("Error during encryption:", error);
		return null; // Opcional: retornar null si hay un error
	}
};

export const DecryptValue = (encryptedValue: string): any | null => {
	try {
		if (encryptedValue !== undefined) {
			const bytes = CryptoJS.AES.decrypt(encryptedValue, secretKey);
			const decrypted = bytes.toString(CryptoJS.enc.Utf8);
			return decrypted;
		}
		return null;
	} catch (error) {
		console.error("Error during decryption:", error);
		return null; // Opcional: retornar null si hay un error
	}
};

export const EncryptValue = (value: string): EncryptedValue | null => {
	try {
		const encryptedValue = CryptoJS.AES.encrypt(value, secretKey).toString();
		return encryptedValue;
	} catch (error) {
		console.error("Error during encryption:", error);
		return null; // Opcional: retornar null si hay un error
	}
};
