import { useState, useContext, useEffect } from "react";
import { Form } from "react-bootstrap";
import { Redirect } from "react-router-dom";
import { SendRequest } from "../../services/RequestHandler";
import Context from "../../context/context";
import { Encrypt, Decrypt } from "../../utils/SessionCrypto/Crypto";
import Identifier from "../../components/Identifier/Identifier";
import Name from "../../components/Name/Name";
import Phone from "../../components/Phone/Phone";
import Email from "../../components/Email/Email";
import Button from "../../components/Button/Button";
import ConfigManager from "../../settings/ConfigManager";

export default function HomePageForm() {
	const { state, actions } = useContext(Context);
	const [formValues, setFormValues] = useState({ identifier: "", name: "", phone: "", email: "" });
	const [validateForm, setValidateForm] = useState({ rut: false, name: false, phone: false, email: false });
	const [toAuthenticationPage, setToAuthenticationPage] = useState(false);
	const [toAffiliatePage, setToAffiliatePage] = useState(false);

	useEffect(() => {
		const fetchValidar = async () => {
			try {
				await setLoading(true);

				if (validateForm.rut && formValues.identifier.length > 7) {
					const upperRut = formValues.identifier.toUpperCase();
					const data = Decrypt("data");

					const response = await fetch(`${ConfigManager.API_URL}/api/Afiliado/Valido`, {
						method: "POST",
						body: JSON.stringify({ Rut: upperRut, Device: data.device }),
						headers: { accept: "application/json", "Content-Type": "application/json" }
					});

					const json = await response.json();

					Encrypt("data", { ...data, run: upperRut });
					setToAffiliatePage(json.data);
				}
			} catch (error) {
				console.error("Error validating RUT:", error);
			} finally {
				await setLoading(false);
			}
		}
		fetchValidar();
	}, [formValues.identifier]);

	const handleSubmit = async (event) => {
		event.preventDefault();

		try {

			await setLoading(true);

			const response = await SendRequest({
				url: `${ConfigManager.API_BACK_URL}/api/Traspaso`,
				method: "POST",
				body: JSON.stringify({
					Rut: formValues.identifier,
					Nombres: formValues.name,
					Celular: `+56${formValues.phone}`,
					Email: formValues.email,
				})
			});

			if (response.code === 0) {
				const fullname = formValues.name.split(" ");

				const data = Decrypt("data");
				data.run = formValues.identifier;
				data.name = fullname[0];
				data.fatherName = fullname[1];
				data.motherName = fullname[2];
				data.phone = formValues.phone;
				data.email = formValues.email;
				data.solicitud = response.data.token;

				Encrypt("data", data);

				setToAuthenticationPage(true);
			}
		} finally {
			await setLoading(false);
		}
	};

	const handleChange = (event) => {
		const { name, value } = event.target;
		setFormValues(values => ({ ...values, [name]: value }));
	};

	const setLoading = async (isLoading) => {
		actions({ type: "setState", payload: { ...state, isLoading } });
	};

	if (toAuthenticationPage) {
		return (<Redirect to={{ pathname: "/autenticacion" }} />);
	} else if (toAffiliatePage) {
		return (<Redirect to={{ pathname: "/yaeresinfo" }} />);
	}

	return (
		<div className="home-container">
			<Form onSubmit={handleSubmit} onChange={handleChange}>
				<div className="mb-1">
					<Form.Label htmlFor="identifier" className="col-form-label">RUT <strong style={{ color: "red" }}>*</strong></Form.Label>
					<Identifier validateForm={validateForm} setValidateForm={setValidateForm} empleadores={null} />
				</div>
				<div className="mb-1">
					<Form.Label htmlFor="name" className="col-form-label">Nombre <strong style={{ color: "red" }}>*</strong></Form.Label>
					<Name validateForm={validateForm} setValidateForm={setValidateForm} />
				</div>
				<div className="mb-1">
					<Form.Label htmlFor="phone" className="col-form-label">Celular <strong style={{ color: "red" }}>*</strong></Form.Label>
					<Phone validateForm={validateForm} setValidateForm={setValidateForm} />
				</div>
				<div className="mb-3">
					<Form.Label htmlFor="email" className="col-form-label">Correo electrónico <strong style={{ color: "red" }}>*</strong></Form.Label>
					<Email validateForm={validateForm} setValidateForm={setValidateForm} />
				</div>
				<div className="mb-1">
					<Button
						id="B-TW-0001"
						type="submit"
						variant="primary"
						disabled={Object.values(validateForm).some(v => !v)}
						loading={state.loading}
						block
					>
						Continuar
					</Button>
				</div>
			</Form>
			{ConfigManager.SHOW_MARKUP && (
				<>
					<div className="col-lg-12">
						<div className="alert alert-danger mt-3" role="alert">
							<pre>{JSON.stringify(formValues, null, "\t")}</pre>
						</div>
					</div>
				</>
			)}
		</div>
	);
}