import "./PersonalPage.scss";
import { useContext, useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { Link, Redirect } from "react-router-dom";
import { SendRequest } from "../../services/RequestHandler";
import Context from "../../context/context";
import StepHeader from "../../components/Header/Ribbon";
import registerStep from "../../services/registerStep";
import { Encrypt, Decrypt } from "../../utils/SessionCrypto/Crypto";
import Button from "../../components/Button/Button";
import InformationForm from "./InformationForm";
import ContactForm from "./ContactForm";
import EmploymentForm from "./EmploymentForm";
import ConfigManager from "../../settings/ConfigManager";
import validator from "validator";
import { isValidIdentifier } from "../../utils/helper/utils";
import Swal from "sweetalert2";
import images from "../../assets/images";

export default function DatosPersonales() {
	const { state, actions } = useContext(Context);
	const [redirect, setRedirect] = useState(false);
	const [afiliado, setafiliado] = useState();
	const [datos, setDatos] = useState();
	const [empleadores, setEmpleadores] = useState([]);
	const [disabled, setDisabled] = useState(true);

	useEffect(() => {
		const fetch = async () => {
			try {
				await setLoading(true);
				registerStep(4);
			} finally {
				await setLoading(false);
			}
		}

		fetch();
	}, []);

	const findRegionByCode = (code) => {

		var regions = Decrypt("states");

		for (const region of regions) {
			for (const municipality of region.municipalities) {
				if (municipality.Code === code) {
					return region;
				}
			}
		}
		return "Código no encontrado";
	};

	const sendAlert = async (object, message, image) => {
		if (object === null || object.trim() === "") {
			await setLoading(false);
			Swal.fire({
				title: "¡Ups!",
				html: `<img src=${image} height="60" /></br></br><p>Lo sentimos, no podemos continuar con la transacción porque el <strong>${message}</strong> de la sesión ha expirado. Serás redirigido a la página de inicio.</p>`,
				confirmButtonText: "Ok",
				allowOutsideClick: false,
			}).then((result) => {
				if (result.isConfirmed) {
					window.location.replace("/");
				}
			});
		}
	}

	useEffect(() => {
		const fetch = async () => {
			try {
				await setLoading(true);

				var dataLocal = Decrypt("data");
				var dataPrevired = Decrypt("misdatos");

				//dataPrevired.response.Datos.Email = dataLocal.email;
				//dataPrevired.response.Datos.Telefono = dataLocal.phone;

				setafiliado(dataPrevired.response.Datos);

				dataPrevired.response.Datos.Solicitud = dataPrevired.response.Solicitud;
				dataPrevired.response.Datos.Token = dataPrevired.response.Token;
				dataPrevired.response.Datos.Ticket = dataPrevired.response.Ticket;

				await sendAlert(dataPrevired.response.Datos.Token, "Token", images.token);
				await sendAlert(dataPrevired.response.Datos.Ticket, "Ticket", images.ticket);
				await sendAlert(dataPrevired.response.Datos.Solicitud, "Token de Solicitud", images.token);

				setDatos(dataPrevired.response.Datos);

				var empleadores = dataPrevired.response.Datos.Empleadores;
				if (empleadores !== undefined && empleadores !== null) {
					var array = [];

					empleadores.map((item, index) => {

						item.codigoUnicoTerritorial = item.codigoUnicoTerritorial === undefined ? item.comunaempleador : item.codigoUnicoTerritorial;

						var a = findRegionByCode(item.codigoUnicoTerritorial)
						var b = a.municipalities.find(x => x.Code === item.codigoUnicoTerritorial);

						array.push({
							id: index + 1,
							razonSocialempleador: item.razonSocialempleador != undefined ? item.razonSocialempleador : item.razonSocial,
							rutempleador: item.rutempleador != undefined ? item.rutempleador : `${item.rutNumero}${item.rutDv}`,
							emailNotifiacionempleador: item.emailNotifiacionempleador != undefined ? item.emailNotifiacionempleador : item.email,
							telefonoempleador: item.telefonoempleador != undefined ? item.telefonoempleador : item.telefonoCelular,
							direccionempleador: item.direccionempleador != undefined ? item.direccionempleador : item.direccion,
							regionempleador: a.Id,
							nombreRegion: a.Name,
							comunaempleador: b.Code,
							nombreComuna: b.Name,
						});
					});
					setEmpleadores(array);
				} else {
					//addEmployer();
				}

			} finally {
				await setLoading(false);
			}
		}
		fetch();
	}, []);

	useEffect(() => {
		const validateData = () => {
			try {
				if (datos === undefined) {
					return false;
				}

				// Verifica que el JSON contenga datos en los campos requeridos
				const isValidSolicitud = Boolean(datos.Solicitud);
				const isValidToken = Boolean(datos.Token);
				const isValidTicketId = Boolean(datos.Ticket);

				// Validar que Cuentas tenga al menos un elemento y que cada cuenta tenga los campos necesarios
				const isValidCuentas = true;

				// Validar los datos personales
				const isValidDatos = datos && (
					//Boolean(datos.Prospecto) &&
					//Boolean(datos.Nombre) &&
					//Boolean(datos.ApellidoPaterno) &&
					//Boolean(datos.ApellidoMaterno) &&
					Boolean(datos.Rut) &&
					Boolean(datos.FechaNacimiento) &&
					Boolean(datos.Nacionalidad) &&
					Boolean(datos.NombreNacionalidad) &&
					Boolean(validator.isEmail(datos.Email)) &&
					Boolean(datos.Telefono.length === 9) &&
					Boolean(datos.Direccion) &&
					Boolean(datos.Region) &&
					Boolean(datos.NombreRegion) &&
					Boolean(datos.Comuna) &&
					Boolean(datos.NombreComuna) &&
					Boolean(datos.Sexo) &&
					Boolean(datos.TipoEmpleado)
				);

				var isValidEmpleadores = true;

				if (datos.TipoEmpleado !== 3 && datos.TipoEmpleado !== 7) {
					if (datos.TipoEmpleado !== 6 && empleadores?.length > 0) {
						isValidEmpleadores = (Array.isArray(empleadores) &&
							empleadores.every(empleador => (
								Boolean(empleador?.razonSocialempleador) &&
								Boolean(isValidIdentifier(empleador?.rutempleador)) &&
								(empleador?.emailNotifiacionempleador ? validator.isEmail(empleador?.emailNotifiacionempleador) : false) &&
								Boolean(empleador?.telefonoempleador?.length === 9) &&
								Boolean(empleador?.direccionempleador) &&
								Boolean(empleador?.comunaempleador) &&
								Boolean(empleador?.nombreComuna)
							)));
					} else {
						if (datos.TipoEmpleado === 6) {
							if (empleadores?.length > 0) {
								isValidEmpleadores = (Array.isArray(empleadores) &&
									empleadores.every(empleador => (
										Boolean(empleador?.razonSocialempleador) &&
										Boolean(isValidIdentifier(empleador?.rutempleador)) &&
										(empleador?.emailNotifiacionempleador ? validator.isEmail(empleador?.emailNotifiacionempleador) : false) &&
										Boolean(empleador?.telefonoempleador?.length === 9) &&
										Boolean(empleador?.direccionempleador) &&
										Boolean(empleador?.comunaempleador) &&
										Boolean(empleador?.nombreComuna)
									)));
							} else {
								isValidEmpleadores = true;
							}
						} else {
							isValidEmpleadores = false;
						}
					}
				}

				const isDisabled = isValidSolicitud && isValidToken && isValidTicketId && isValidCuentas && isValidDatos && isValidEmpleadores;

				setDisabled(!isDisabled)
			} catch (message) {
				console.error(message);
			} finally {

			}
		};
		validateData();
	}, [datos, empleadores]);

	const handleSubmit = async (e) => {
		e.preventDefault();

		try {
			await setLoading(true);
			var data = Decrypt("data");

			var misdatos = Decrypt("misdatos");
			misdatos.response.Datos = datos;
			misdatos.response.Datos.Empleadores = empleadores;

			Encrypt("misdatos", misdatos);

			let dataLog = {
				Rut: data.run,
				Autenticacion: data.autenticacion,
				Device: data.device,
				Json: JSON.stringify({
					response: {
						solicitud: misdatos.response.Solicitud,
						token: misdatos.response.Token,
						ticketId: misdatos.response.Ticket,
						cuentasEditadas: misdatos.response.Cuentas,
						datosPersonales: misdatos.response.Datos
					}
				})
			};

			await SendRequest({
				url: `${ConfigManager.API_BACK_URL}/api/log/datos`,
				method: 'POST',
				body: JSON.stringify(dataLog),
			});

			setRedirect(true);
		} finally {
			await setLoading(false);
		}
	};

	const setLoading = async (isLoading) => {
		actions({ type: "setState", payload: { ...state, isLoading } });
	};

	if (redirect) {
		return (<Redirect to={{ pathname: "/confirmartraspaso" }} />);
	}

	return (
		<>
			<StepHeader paso="2" />
			<Form onSubmit={handleSubmit} className="offset-1 col-10 offset-sm-1 col-sm-10 offset-md-1 col-md-10 offset-lg-3 col-lg-6 personal">
				{afiliado && (<InformationForm data={afiliado} />)}
				{datos && (<ContactForm datos={datos} setDatos={setDatos} setLoading={setLoading} />)}
				{datos && (
					<EmploymentForm
						datos={datos}
						setDatos={setDatos}
						empleadores={empleadores}
						setEmpleadores={setEmpleadores}
						setLoading={setLoading}
					/>)}

				<div className="container mt-1 mb-4">
					<div className="row">
						<div className="col text-center">
							<Button id="B-TW-0008" type="submit" variant="primary" loading={state.loading} disabled={disabled} >
								Continuar
							</Button>
						</div>
					</div>
					<div className="row mt-3">
						<div className="col text-center">
							<Link to={{ pathname: "/cuentasyfondo" }} className="link">
								Volver
							</Link>
						</div>
					</div>
				</div>
			</Form >
		</>
	);
}