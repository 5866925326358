import "./AuthenticationPage.scss";
import { useContext, useState, useEffect } from "react";
import { Card, Accordion, Modal, Form } from "react-bootstrap";
import { SendRequest } from "../../services/RequestHandler";
import { useLocation } from "react-router-dom";
import { isMobile, isBrowser } from 'react-device-detect';
import Context from "../../context/context";
import registerStep from "../../services/registerStep";
import ConfigManager from "../../settings/ConfigManager";
import images from "../../assets/images";
import Disclaimer from "../../components/Disclaimer/Disclaimer";
import FormClaveUnica from "./FormClaveUnica";
import FormClaveSeguridad from "./FormClaveSeguridad";
import Identifier from "../../components/Identifier/Identifier";
import Button from "../../components/Button/Button";
import { Encrypt, Decrypt, DecryptValue, EncryptValue } from "../../utils/SessionCrypto/Crypto";

export default function AuthenticationPage() {

	const message = !ConfigManager.ACTIVE_FAO
		? "Si sólo posees cuenta obligatoria podrás utilizar Clave Única como método de autenticación."
		: "Te invitamos a validar tu identidad con Clave Única y responder correctamente las preguntas de seguridad.";

	const params = new URLSearchParams(useLocation().search);

	const [activeKey, setActiveKey] = useState("0");
	const { state, actions } = useContext(Context);
	const [enableCU, setEnableCU] = useState(true);

	const backaccess = DecryptValue(params.get("backaccess")?.replace(' ', '+')) === "true";
	const identifier = DecryptValue(params.get("identifier")?.replace(' ', '+'));

	const [show, setShow] = useState(backaccess && identifier !== Decrypt("data")?.run);

	const [formValues, setFormValues] = useState({ identifier: "", name: "", phone: "", email: "" });
	const [validateForm, setValidateForm] = useState({ rut: false, name: false, phone: false, email: false });

	const [device] = useState(isMobile ? "Mobile" : isBrowser ? "Browser" : "Unknown");
	const [ip, setIp] = useState('');

	useEffect(() => {
		const fetchConfiguration = async () => {
			try {
				setLoading(true);
				const result = await SendRequest({
					url: `${ConfigManager.API_BACK_URL}/api/configuration`,
					method: "GET",
					body: null,
				});

				if (result.code === 0) {
					registerStep(2);
					setEnableCU(result.data.cuVisible);
				}
			} catch (error) {
				console.error('Error fetching configuration:', error);
			} finally {
				setLoading(false);
			}
		};

		fetchConfiguration();
	}, []);

	const handleSelect = (key) => {
		setActiveKey(prevKey => key === null ? (prevKey === "0" ? "1" : "0") : key);
	};

	const setLoading = async (isLoading) => {
		actions({ type: "setState", payload: { ...state, isLoading } });
	};

	const handleChange = (event) => {
		const { name, value } = event.target;
		setFormValues(values => ({ ...values, [name]: value }));
	};

	const validarDatos = async (event) => {
		event.preventDefault();

		try {
			const response = await fetch('https://api.ipify.org?format=json', { method: "GET" });
			const json = await response.json();

			Encrypt("data", { device: device, ip: json.ip, isAuthenticated: false });
			setIp(json.ip);
		} catch (error) {
			console.error('Error fetching IP address:', error);
		}

		try {

			await setLoading(true);

			const decrypted = Decrypt("data");
			decrypted.backaccess = true;
			var response = await fetch(`${ConfigManager.API_URL}/api/Afiliado/Informacion`, {
				method: "POST",
				headers: ConfigManager.JSON_HEADER,
				body: JSON.stringify({
					Rut: formValues.identifier,
					Device: decrypted.device
				})
			});

			const result = await response.json();
			if (result.code === 0) {
				decrypted.run = formValues.identifier;
				decrypted.name = result.data.nombre;
				decrypted.fatherName = result.data.apPaterno;
				decrypted.motherName = result.data.apMaterno;
				decrypted.phone = result.data.celular;
				decrypted.email = result.data.email;
			}

			if (result.code === 3) {
				decrypted.run = formValues.identifier;
				decrypted.name = "sn";
				decrypted.fatherName = "";
				decrypted.motherName = "";
				decrypted.phone = "";
				decrypted.email = "sn@correo.cl";
			}

			var response2 = await SendRequest({
				url: `${ConfigManager.API_BACK_URL}/api/Traspaso`,
				method: "POST",
				body: JSON.stringify({
					Rut: decrypted.run,
					Nombres: `${decrypted.name} ${decrypted.fatherName} ${decrypted.motherName}`,
					Celular: decrypted.phone,
					Email: decrypted.email,
					Ip: decrypted.ip
				})
			});

			if (response2.code === 0) {
				decrypted.solicitud = response2.data.token;
			}
			Encrypt("data", decrypted);

		} catch (error) {
			console.error("Error validating:", error);
		} finally {
			await setLoading(false);
			setShow(false);
		}
	};

	return (
		<>
			<div className="container mt-2 mb-4">
				<div className="row">
					<div className="col-sm-12 col-lg-4">
						<div className="row">
							<div className="col-12">
								<h2 className="mt-3 text-center">
									¡Cámbiate a AFP Modelo!
								</h2>
							</div>
							<div className="col-12 text-center">
								<span>Haz tu solicitud de <strong> cambio online fácil y rápido.</strong></span>
							</div>
							<div className="col-12 d-grid gap-2">
								<div className="mx-auto" style={{ width: "200px" }}>
									<img src={images.cerditoMobile} className="d-block d-lg-none" width={150} alt="mobile" />
								</div>
							</div>
							<div className="mt-3 offset-lg-1 col-lg-10">
								<Accordion activeKey={activeKey} onSelect={handleSelect}>
									{enableCU && (
										<Card className="mb-3">
											<Accordion.Toggle className="collapse-accordion" eventKey="0">
												<Card.Header>
													<div className="row">
														<div className="col-10">
															<img src={images.claveUnica} alt="Clave Única" width={250} />
														</div>
														<div className="col-2 text-right">
															<img src={activeKey === "0" ? images.up : images.down} alt="arrow" width="20" />
														</div>
													</div>
												</Card.Header>
												<div className="row mt-3">
													<div className="col-12">
														<p className="texto-caja">
															{message}
														</p>
													</div>
												</div>
											</Accordion.Toggle>
											<Accordion.Collapse eventKey="0">
												<Card.Body>
													<FormClaveUnica setLoading={setLoading} />
												</Card.Body>
											</Accordion.Collapse>
										</Card>
									)}

									<Card>
										<Accordion.Toggle className="collapse-accordion" eventKey="1">
											<Card.Header>
												<div className="row">
													<div className="col-2">
														<img src={images.key} alt="key" className="identificacion-icon" />
													</div>
													<div className="col-8 text-center">
														<h5>Clave de seguridad</h5>
													</div>
													<div className="col-2 text-right">
														<img src={activeKey === "1" ? images.up : images.down} alt="arrow" width="20" />
													</div>
												</div>
											</Card.Header>
											<div className="row mt-3">
												<div className="col-12">
													<p className="texto-caja">
														Es la segunda Clave de AFP, te permite hacer Cambio de fondos y <strong>cambio de AFP.</strong>
													</p>
												</div>
											</div>
										</Accordion.Toggle>
										<Accordion.Collapse eventKey="1">
											<Card.Body className="bg-white">
												<FormClaveSeguridad setLoading={setLoading} />
											</Card.Body>
										</Accordion.Collapse>
									</Card>
								</Accordion>
							</div>
							<div className="col-12">
								<p className="mt-3 text-center text-small">
									Te sugerimos que tengas los datos de tu empleador y cédula
									de identidad a mano para hacer este cambio mucho más
									rápido.
								</p>
							</div>
						</div>
					</div>
					<div className="col-sm-12 col-lg-4 mt-5 d-grid gap-2">
						<div className="mx-auto" style={{ width: "300px" }}>
							<img src={images.cerditoDesktop} className="d-none d-lg-block" width={300} alt="desktop" />
						</div>
					</div>
					<div className="col-sm-12 col-lg-4 mt-5 d-none d-lg-block">
						<Disclaimer />
					</div>
				</div>
			</div>
			<Modal show={show} onHide={() => setShow(false)} animation={false} backdrop="static" className="">
				<Modal.Body>
					<div className="container">
						<Form onChange={handleChange}>
							<div className="mb-1">
								<h2 className="mt-4" style={{ color: "#81BD00" }}>
									¡Estás a un paso de comenzar tu traspaso a AFP Modelo!
								</h2>

								<p className="mt-4" style={{ fontSize: "14px", textAlign: "justify" }}>
									Antes de continuar, necesitamos validar tu RUT para asegurarnos de que cumples con los requisitos para este proceso. Es un paso rápido y sencillo que garantiza un traspaso seguro y sin complicaciones.
								</p>
							</div>
							<div className="mb-1">
								<Form.Label htmlFor="identifier" className="col-form-label"><strong style={{ color: "red" }}>*</strong> RUT</Form.Label>
								<Identifier validateForm={validateForm} setValidateForm={setValidateForm} empleadores={null} />

								<p style={{ fontSize: "14px", textAlign: "center" }}>
									Haz clic en 'Iniciar traspaso' para comenzar.
								</p>
							</div>
							<div className="mt-4 mb-4">
								<Button
									id="B-TW-0012"
									type="submit"
									variant="primary"
									disabled={!validateForm.rut}
									loading={state.loading}
									onClick={validarDatos}
									block
								>
									Iniciar traspaso
								</Button>
							</div>
						</Form>
					</div>
				</Modal.Body>
			</Modal>
		</>
	);
}